export const reports = [
  {
    path: "/reports/general",
    name: "GeneralReports",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/Index.vue"
      ),
    meta: {
      title: "Reports",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-orders",
    name: "OrdersReports",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/PurchaseOrders.vue"
      ),
    meta: {
      title: "Orders Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-order-invoice/:reference",
    name: "OrdersInvoice",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/OrderInvoice.vue"
      ),
    meta: {
      title: "Orders Invoice",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/store-status",
    name: "StoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/StoreStatus.vue"
      ),
    meta: {
      title: "Store Status Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receive",
    name: "ReceiveReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/Receive.vue"
      ),
    meta: {
      title: "Stock Receing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receive/:reference",
    name: "ReceiveVoucher",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ReceiveVoucher.vue"
      ),
    meta: {
      title: "Receive Voucher",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer",
    name: "TransferReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/Transfer.vue"
      ),
    meta: {
      title: "Stock Transfer Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer/items",
    name: "ItemTransferReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ItemTransfer.vue"
      ),
    meta: {
      title: "Items Transfer Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer/voucher/:reference",
    name: "TransferVoucher",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/TransferVoucher.vue"
      ),
    meta: {
      title: "Transfer Voucher",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase/items",
    name: "ItemPurchaseReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ItemPurchase.vue"
      ),
    meta: {
      title: "Items Purchase Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/store/:code/tracker",
    name: "ItemTracker",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ItemTracker.vue"
      ),
    meta: {
      title: "Item Tracking History",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/recipes-costing",
    name: "RecipesCosting",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/RecipesCosting.vue"
      ),
    meta: {
      title: "Recipes Costing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/suppliers/balance",
    name: "SupplierBalance",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/SupplierBalance.vue"
      ),
    meta: {
      title: "Supplier Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/spoiled-items",
    name: "SpoiledItemsReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/SpoiledItems.vue"
      ),
    meta: {
      title: "Spoiled Items Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production-sales",
    name: "ProductionSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Production Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/account/transactions",
    name: "AccountTransactions",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/AccountTransactions.vue"
      ),
    meta: {
      title: "Account Transactions Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/accounts/transfers",
    name: "AccountsTransfers",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/AccountTransfers.vue"
      ),
    meta: {
      title: "Accounts Transfers Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/sales/items",
    name: "ItemSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ItemSales.vue"
      ),
    meta: {
      title: "Items Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales/customers",
    name: "SalesByCustomer",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/CustomerSales.vue"
      ),
    meta: {
      title: "Sales by Customers Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales/customers/balance",
    name: "CustomerBalance",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/CustomerBalance.vue"
      ),
    meta: {
      title: "Customers Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/produced-items",
    name: "ProducedItemsReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ProducedItems.vue"
      ),
    meta: {
      title: "Produced Items Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production-stock-status",
    name: "ProductionStoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Production/Stock.vue"
      ),
    meta: {
      title: "Production Store Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/stock/:code/track",
    name: "ProductionItemTracker",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ItemTracker.vue"
      ),
    meta: {
      title: "Item Movemenets History",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/store-status",
    name: "BranchStoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Branches/Stock.vue"
      ),
    meta: {
      title: "Store Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receiving-report",
    name: "BranchReceingReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Branches/ReceivesReport.vue"
      ),
    meta: {
      title: "Receiving Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-report",
    name: "PurchasesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Branches/PurchasesReport.vue"
      ),
    meta: {
      title: "Purchase Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/sales",
    name: "BranchesSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Production Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/customers/balance",
    name: "BranchCustomerBalance",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/CustomerBalance.vue"
      ),
    meta: {
      title: "Customers Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/sales/items",
    name: "BranchItemSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ItemSales.vue"
      ),
    meta: {
      title: "Items Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/dispatch",
    name: "DispatchReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/Dispatch.vue"
      ),
    meta: {
      title: "Dispatch Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/suppliers/payments-made",
    name: "SupplierPaymentsMade",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/PaymentsMade.vue"
      ),
    meta: {
      title: "Payments Made",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales/payments-received",
    name: "PaymentsReceived",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/PaymentsReceived.vue"
      ),
    meta: {
      title: "Payments Received",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/app/activity-logs",
    name: "ActivityLogs",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ActivityLogs.vue"
      ),
    meta: {
      title: "Activity Logs",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/app/login-activities",
    name: "LoginActivities",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/LoginActivities.vue"
      ),
    meta: {
      title: "Login Activities",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/physical-count-report",
    name: "PhysicalCountReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/PhysicalCountReport.vue"
      ),
    meta: {
      title: "Physical Count Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales-analysis-report",
    name: "SalesAnalysisReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/SalesAnalysisReport.vue"
      ),
    meta: {
      title: "Sales Analysis Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/profit-and-loss",
    name: "ProfitLossReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/Views/Reports/ProfitLossReport.vue"
      ),
    meta: {
      title: "Profit and Loss Report",
      requiresAdminAuth: true,
    },
  },
];
