<template>
  <div class="nk-sidebar nk-sidebar-fixed sidebar-open" style="z-index: 999">
    <div class="nk-sidebar-element nk-sidebar-head px-0 py-1">
      <div class="nk-sidebar-brand">
        <a href="javascript:void(0)" class="logo-link nk-sidebar-logo"
          ><img
            class="logo-light logo-img img-fluid"
            :src="`${publicPath}img/logo-mini.jpg`"
            alt="logo" /><img
            class="logo-dark logo-img img-fluid"
            :src="`${publicPath}img/logo.jpg`"
            alt="logo-dark"
        /></a>
      </div>
    </div>
    <div class="nk-sidebar-element">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu" data-simplebar="init">
          <div class="simplebar-wrapper" style="margin: -16px 0px -40px">
            <div class="simplebar-height-auto-observer-wrapper">
              <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
              <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                <div
                  class="simplebar-content-wrapper"
                  style="height: 100%; overflow: hidden scroll"
                >
                  <div class="simplebar-content" style="padding: 16px 0px 40px">
                    <ul class="nk-menu" v-if="$helper.empty(loggedUser.branch)">
                      <template v-for="menu in menus">
                        <li
                          class="nk-menu-item has-sub"
                          v-if="
                            !$helper.empty(menu.submenus) &&
                            isMenuAccessible(
                              $helper.createTitleSlug(menu.title)
                            )
                          "
                          :key="menu.title"
                        >
                          <a
                            @click.prevent="showSubMenus(menu.title)"
                            href="javascript:void(0)"
                            :id="menu.title"
                            class="nk-menu-link nk-menu-toggle"
                            ><span class="nk-menu-icon"
                              ><em :class="`icon ${menu.icon}`"></em></span
                            ><span class="nk-menu-text text-capitalize">{{
                              $helper.capitalizify(menu.title) | trans
                            }}</span>
                            <span
                              class="nk-menu-badge"
                              v-if="hasNotifications(menu.notifications)"
                              >{{
                                totalNotifications(menu.notifications)
                              }}</span
                            >
                          </a>
                          <ul class="nk-menu-sub">
                            <template v-for="(item, i) in menu.submenus">
                              <li
                                class="nk-menu-item"
                                v-if="
                                  canView(
                                    [
                                      $helper.createTitleSlug(menu.title),
                                      'submenus',
                                      i,
                                    ].join('.')
                                  )
                                "
                                :key="i"
                              >
                                <router-link :to="item" class="nk-menu-link"
                                  ><span class="nk-menu-text text-capitalize">{{
                                    $helper.inputTitle(i) | trans
                                  }}</span></router-link
                                >
                              </li>
                            </template>
                          </ul>
                        </li>
                        <li
                          class="nk-menu-item"
                          v-else-if="
                            isMenuAccessible(
                              $helper.createTitleSlug(menu.title)
                            )
                          "
                          :key="menu.title"
                        >
                          <router-link
                            :to="menu.url"
                            class="nk-menu-link single-menu-item"
                            ><span class="nk-menu-icon"
                              ><em :class="`icon ni ${menu.icon}`"></em></span
                            ><span class="nk-menu-text text-capitalize">{{
                              menu.title | trans
                            }}</span></router-link
                          >
                        </li>
                      </template>
                      <li
                        class="nk-menu-item"
                        v-if="isMenuAccessible('front_office')"
                      >
                        <a
                          :href="`https://sales.tameaps.com/?url=${
                            selfLocation + baseURL
                          }&token=${currentToken}`"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni ni-cart"></em></span
                          ><span class="nk-menu-text text-capitalize"
                            >Front Office</span
                          ></a
                        >
                      </li>
                    </ul>
                    <ul class="nk-menu" v-else>
                      <template v-for="menu in branchMenus">
                        <li
                          class="nk-menu-item"
                          :key="menu.title"
                          v-if="
                            !menu.submenus && canAccessBranchMenu(menu.title)
                          "
                        >
                          <router-link :to="menu.url" class="nk-menu-link"
                            ><span
                              class="nk-menu-icon"
                              v-html="menu.icon"
                            ></span
                            ><span class="nk-menu-text">{{
                              menu.alias
                            }}</span></router-link
                          >
                        </li>
                        <li
                          class="nk-menu-item has-sub"
                          v-else-if="canAccessBranchMenu(menu.title)"
                          :key="menu.title"
                        >
                          <a
                            href="javascript:void(0)"
                            :id="menu.title"
                            @click.prevent="showSubMenus(menu.title)"
                            class="nk-menu-link nk-menu-toggle"
                            ><span
                              class="nk-menu-icon"
                              v-html="menu.icon"
                            ></span
                            ><span class="nk-menu-text text-capitalize">{{
                              menu.alias
                            }}</span></a
                          >
                          <ul class="nk-menu-sub">
                            <template v-for="(item, i) in menu.submenus">
                              <li
                                class="nk-menu-item"
                                :key="i"
                                v-if="canAccessBranchMenu(menu.title, i)"
                              >
                                <router-link :to="item" class="nk-menu-link"
                                  ><span class="nk-menu-text text-capitalize">{{
                                    $helper.inputTitle(i) | trans
                                  }}</span></router-link
                                >
                              </li>
                            </template>
                          </ul>
                        </li>
                      </template>
                      <li class="nk-menu-item">
                        <a
                          :href="`https://sales.tameaps.com/?url=${
                            selfLocation + baseURL
                          }&token=${currentToken}`"
                          class="nk-menu-link single-menu-item"
                          ><span class="nk-menu-icon"
                            ><em class="icon ni ni ni-cart"></em></span
                          ><span class="nk-menu-text text-capitalize"
                            >Front Office</span
                          ></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="simplebar-placeholder"
              style="width: auto; height: 585px"
            ></div>
          </div>
          <div
            class="simplebar-track simplebar-horizontal"
            style="visibility: hidden"
          >
            <div
              class="simplebar-scrollbar"
              style="width: 0px; display: none"
            ></div>
          </div>
          <div
            class="simplebar-track simplebar-vertical"
            style="visibility: visible"
          >
            <div
              class="simplebar-scrollbar"
              style="
                height: 81px;
                transform: translate3d(0px, 138px, 0px);
                display: block;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { menus } from "@/_helpers/menus.js";
import { baseURL } from "@/_helpers/utils.js";
import { branchMenus } from "./branch-menus.js";

export default {
  name: "SideBar",
  data: () => ({
    menus,
    baseURL,
    branchMenus,
  }),
  watch: {
    $route() {
      var activeLink = document.querySelector(
        "a[href='" + location.pathname + "']"
      );
      if (activeLink) {
        let parent = activeLink.closest(".nk-menu-item.has-sub");
        if (parent) {
          parent.classList.add("active");
        }
      }
    },
  },
  created() {
    this.$http.get("shared/notifications").then((response) => {
      if (response.data.status) {
        this.$store.commit("SET_MENU_NOTIFICATIONS", response.data.records);
      }
    });
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications,
    }),
    selfLocation() {
      return window.location.origin;
    },
    currentToken() {
      return localStorage.getItem("token");
    },
    isReady() {
      return this.loggedUser && Object.keys(this.loggedUser).length > 0;
    },
    branchPermissions() {
      return this.isReady && this.loggedUser.role.branch_permissions;
    },
  },
  methods: {
    canAccessBranchMenu(menu, submenu = null) {
      if (!submenu) {
        return (
          this.branchPermissions[menu] &&
          this.branchPermissions[menu].accessible == true
        );
      } else {
        return (
          this.branchPermissions[menu] &&
          this.branchPermissions[menu][submenu] &&
          this.branchPermissions[menu][submenu].includes("R")
        );
      }
    },
    showSubMenus(id) {
      var parent = document.querySelector(`a#${id}`).parentElement;
      [...document.querySelectorAll(".nk-menu-item.has-sub")].forEach((li) => {
        if (li != parent) li.classList.remove("active");
      });
      parent.classList.toggle("active");
    },
    hasNotifications(keys) {
      return keys.some((item) => this.notifications[item] != 0);
    },
    totalNotifications(keys) {
      return keys.reduce(
        (prev, next) => prev + Number(this.notifications[next]),
        0
      );
    },
    isMenuAccessible(menu) {
      if (this.userRole == "ADMINISTRATOR") return true;
      return (
        this.userPermissions[menu] &&
        this.userPermissions[menu].accessible == true
      );
    },
  },
  mounted() {
    const routes = {};
    branchMenus.forEach((menu) => {
      if (!menu.submenus) {
        routes[menu.url.name] = menu.title;
      } else {
        for (let item in menu.submenus) {
          routes[menu.submenus[item].name] = `${menu.title}.${item}`;
        }
      }
    });
    this.$store.state.routes = routes;
  },
};
</script>
<style>
.logo-img {
  max-height: unset;
}
@media (min-width: 1200px) {
  .nk-sidebar {
    width: 200px;
  }
  .preloader {
    left: 200px;
    top: 63px;
  }
  .preloader .preloader-inner {
    left: calc(50% - 200px);
  }
}

@media (max-width: 1200px) {
  .logo-img {
    max-height: unset;
  }
  .nk-sidebar-overlay {
    z-index: -1;
    left: 200px;
  }
}
.nk-sidebar {
  width: 200px;
}
.nk-sidebar + .nk-wrap,
.nk-sidebar-overlay + .nk-wrap {
  padding-left: 200px;
}
.nk-sidebar + .nk-wrap > .nk-header-fixed,
.nk-sidebar-overlay + .nk-wrap > .nk-header-fixed {
  left: 200px;
}
.nk-menu-icon .icon {
  font-size: 18px;
}
.nk-menu-item {
  padding: 3px 0;
}
.nk-menu-icon {
  width: 22px;
}
.nk-menu-link {
  font-size: 13px;
  font-weight: 500;
}
.nk-menu-link,
.nk-menu-toggle:after {
  color: #00123e;
}

.active .nk-menu-toggle:after,
.has-sub:hover .nk-menu-toggle:after {
  color: #fff;
}
.nk-sidebar {
  background: #efefef;
}
.nk-header.is-light:not([class*="bg-"]) {
  background: #f7f7f7;
}

.single-menu-item.router-link-exact-active,
.nk-menu-link:hover,
.active > .nk-menu-link {
  color: #fff;
  background: #00123e;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.single-menu-item.router-link-exact-active > .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #fff;
}

.nk-menu-link,
.nk-menu-toggle:after {
  color: #00123e;
}
.nk-sidebar-head {
  width: 200px;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link {
  padding-left: 35px;
  padding-right: 0;
}
.nk-sidebar-logo {
  width: 200px;
}
@media (max-width: 768px) {
  .nk-sidebar + .nk-wrap,
  .nk-sidebar-overlay + .nk-wrap {
    padding-left: 0 !important;
  }
  .nk-sidebar {
    width: 0 !important;
    z-index: 9999;
  }
  .logo-light {
    opacity: 1;
  }
  .logo-dark {
    opacity: 0;
  }
  .nk-sidebar-logo,
  .nk-sidebar-head {
    width: 60px;
  }
  .nk-header.is-light:not([class*="bg-"]) {
    background: #fff !important;
  }
  .nk-sidebar + .nk-wrap > .nk-header-fixed {
    left: 0px;
  }
  .nk-quick-nav {
    margin: 0;
    margin-right: 7px;
  }
}
</style>
