import { Printd } from "printd";
export default {
  install(Vue) {
    Vue.prototype.$helper = {
      timeZone: localStorage.getItem("_tz") || "Africa/Kigali",
      baseUrl(tail = null) {
        let result = location.origin;
        if (tail) result += `/${tail}`;
        return result;
      },
      formatNumber(number) {
        if (!number) {
          return 0;
        }
        let str = number.toString();
        const decimalIndex = str.indexOf(".");
        const decimalPlaces = 3;
        if (decimalIndex !== -1) {
          // Limit the decimal places without rounding
          const limitedDecimal = str.substr(decimalIndex + 1, decimalPlaces);
          // Construct the resulting string
          str = str.substr(0, decimalIndex + 1) + limitedDecimal;
        }
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      reloadPage() {
        location.reload();
      },
      showFilePicker(inputFileId) {
        document.getElementById(inputFileId).click();
      },
      isEmpty(obj) {
        const isNULL = Object.values(obj).every((x) => x === null);
        return Object.keys(obj).length === 0 || isNULL === true;
      },
      empty(mixedVar) {
        let undef, key, i, len;
        const emptyValues = [undef, null, false, 0, "", "0"];
        for (i = 0, len = emptyValues.length; i < len; i++) {
          if (mixedVar === emptyValues[i]) {
            return true;
          }
        }
        if (typeof mixedVar === "object") {
          for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
              return false;
            }
          }
          return true;
        }
        return false;
      },
      handleArrayPush(array, newItem) {
        return [...[newItem], ...array];
      },
      handlePrint(elt) {
        const d = new Printd();
        const css = [
          `${location.origin}/css/styles.css`,
          `${location.origin}/css/custom.css`,
        ];

        d.print(document.querySelector(elt), css);
      },
      resetObjectValues(obj) {
        for (let key in obj) {
          if (obj[key] === true) obj[key] = false;
          else if (obj[key] === false) continue;
          else obj[key] = null;
        }
        return obj;
      },
      formatDate(str) {
        let options = {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: this.timeZone,
        };
        let today = new Date(str);
        return today.toLocaleDateString("en-US", options);
      },
      formatTime(str) {
        return new Date(str).toLocaleTimeString("en-US", {
          timeZone: this.timeZone,
        });
      },
      generateFormData(obj) {
        let formData = new FormData();
        for (let key in obj) {
          if (obj[key] !== null && typeof obj[key] !== "undefined") {
            if (typeof obj[key] === "object")
              formData.append(key, JSON.stringify(obj[key]));
            else formData.append(key, obj[key]);
          }
        }
        return formData;
      },
      toggleModalOpen() {
        const elt = document.documentElement;
        if (elt.classList.contains("modal-open"))
          elt.classList.remove("modal-open");
        else elt.classList.add("modal-open");

        const el = document.body;
        if (el.classList.contains("modal-open"))
          el.classList.remove("modal-open");
        else el.classList.add("modal-open");
      },
      encodeQuery(url, data) {
        let query = "";
        for (let d in data) {
          if (
            !this.empty(data[d]) &&
            url.indexOf(`?${d}`) < 0 &&
            url.indexOf(`&${d}`) < 0
          )
            query +=
              encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
        }
        return url.indexOf("?") > -1
          ? `${url}&${query.slice(0, -1)}`
          : `${url}?${query.slice(0, -1)}`;
      },
      generateVoucherNo(no) {
        let len = no.toString().length;
        if (len >= 4) return no;
        if (len == 1) return `000${no}`;
        if (len == 2) return `00${no}`;
        if (len == 3) return `0${no}`;
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      inputTitle(text) {
        return text.split("_").join(" ");
      },
      createTitleSlug(text) {
        return text.toLowerCase().split(" ").join("_");
      },
      createKeyPath(keys = []) {
        return keys.join(".");
      },
      getKeyValue(path, obj) {
        return path.split(".").reduce((p, c) => (p && p[c]) || null, obj);
      },
      incrementObjectKey(obj, key, max = null) {
        if (max) {
          if (Number(obj[key]) <= max) {
            obj[key] = Number(obj[key]) + 1;
          }
        } else {
          obj[key] = Number(obj[key]) + 1;
        }
      },
      decrementObjectKey(obj, key, min = 1) {
        if (Number(obj[key]) > min) {
          obj[key] = Number(obj[key]) - 1;
        }
      },
      capitalizify(phrase) {
        return phrase
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      },
      generateRandomText(length) {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      },
      getMonthBoundaries(date) {
        // Get the current date
        const currentDate = new Date(TODAY);
        // Get the start of the month
        const startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        )
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);

        // Get the end of the month
        const endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        )
          .toLocaleString("fr-CA", { timeZone: TIMEZONE })
          .slice(0, 10);

        return [startDate, endDate];
      },
    };
  },
};
