export const encodeQuery = (url, data) => {
  let query = "";
  for (let d in data) {
    if (data[d] && url.indexOf(`?${d}`) < 0 && url.indexOf(`&${d}`) < 0)
      query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
  }
  return url.indexOf("?") > -1
    ? `${url}&${query.slice(0, -1)}`
    : `${url}?${query.slice(0, -1)}`;
};

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/inventory/"
    : "http://127.0.0.1:8000/api/inventory/";

export const getURIParams = () => {
  return location.search
    .substring(1)
    .split("&")
    .map((v) => v.split("="))
    .reduce((obj, [key, value]) => {
      obj[key] = decodeURIComponent(value);
      return obj;
    }, Object.create(null));
};

export const uploadsUrl =
  process.env.NODE_ENV === "production"
    ? "/api/uploads/"
    : "http://127.0.0.1:8000/uploads/";
