<template>
  <div>
    <div
      class="modal fade show"
      id="modal-default"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <div class="modal-content rounded-0" style="border-color: transparent">
          <div class="modal-header px-2 py-1">
            <h6 class="modal-title">
              <h4 class="modal-title text-semibold" v-if="false">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="icon icon-xxlg align-top"
                >
                  <path
                    fill="#FF4141"
                    d="M142 455.6c5.6 14.9 20.1 25.5 36.8 25.5h154.4c20.6 0 37.9-16.2 39.3-36.7l10.4-154.1c-50 24.9-200.5 113.1-240.9 165.3z"
                  ></path>
                  <path
                    d="M440.8 72.1h-84.7l-30-54.3c-4.2-8.2-12.6-13.2-21.7-13.2h-96.8c-9.2 0-17.5 5-21.7 13.2l-30 54.3H71.1C63.9 72.1 58 78 58 85.2c0 7.2 5.9 13.1 13.1 13.1h18.7l23.5 347.8c2.3 34.3 31.1 61.2 65.5 61.2h154.4c12.9 0 25-3.8 35.2-10.3 1.6-1 3.2-2.1 4.7-3.3 14.5-11.1 24.3-28.2 25.6-47.6l23.5-347.8h18.7c7.2 0 13.1-5.9 13.1-13.1s-6-13.1-13.2-13.1zM208.6 30.9h94.8l22.8 41.2H185.8l22.8-41.2zm124.6 450.2H178.8c-16.7 0-31.2-10.6-36.8-25.5-1.3-3.5-2.2-7.3-2.4-11.2L116.1 98.3h279.7l-13 192-10.4 154.1c-1.3 20.6-18.6 36.7-39.2 36.7z"
                  ></path>
                </svg>
                Delete Your Organization
              </h4>
              {{ modalTitle }}
            </h6>
            <a
              href="javascript:void(0)"
              class="close"
              @click.prevent="$emit('close')"
            >
              <em class="icon ni ni-cross"></em
            ></a>
          </div>

          <div class="modal-body text-left">
            <div v-html="modalContent"></div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              :class="'mr-auto ' + actionButtonClasses"
              @click="$emit('accept-alert')"
            >
              <template
                v-if="
                  $store.state.isLoading &&
                  $store.state.requestFlag === 'deletingRecord'
                "
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Please wait...</span>
              </template>
              <template v-else>{{ actionButton }}</template>
            </button>
            <button
              v-if="!singleButton"
              type="button"
              class="btn btn-dark ms-auto"
              @click="$emit('close')"
            >
              {{ closeButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  name: "BootstrapAlert",
  props: {
    modalTitle: String,
    modalContent: {
      type: String,
      required: true,
    },
    singleButton: {
      type: Boolean,
      default: false,
    },
    actionButton: {
      type: String,
      required: true,
    },
    actionButtonClasses: {
      type: String,
      required: true,
    },
    closeButton: {
      type: String,
      default: "close",
    },
  },
  created() {
    this.$helper.toggleModalOpen();
  },
  destroyed() {
    this.$helper.toggleModalOpen();
  },
};
</script>
