<template>
  <div>
    <transition name="bounce">
      <div
        v-if="!$helper.empty(flashMessage)"
        id="ember201"
        class="notification-message text-center zf-notification-container ember-view"
        style="
          transform: translateY(0px);
          opacity: 1;
          transition: transform 0.6s ease 0s;
        "
      >
        <div align="center">
          <div :class="`message-container ${flashMessage.type}`">
            <div>
              <svg
                class="svg-icon"
                v-if="flashMessage.type == 'warning'"
                viewBox="0 0 17 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                ></path>
              </svg>

              <svg
                v-if="flashMessage.type == 'info'"
                class="svg-icon"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                ></path>
              </svg>
              <svg
                v-if="flashMessage.type == 'success'"
                class="svg-icon"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                ></path>
              </svg>

              <svg
                class="svg-icon"
                v-if="flashMessage.type == 'danger'"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                ></path>
              </svg>
            </div>
            <div class="msg">
              <span>{{ flashMessage.message | trans }}</span>
            </div>
            <div @click="$store.commit('SET_FLASH_MESSAGE', {})">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                xml:space="preserve"
                class="icon-remove"
              >
                <path
                  d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="nk-app-root sheet padding-10mm" v-if="isUserLoggedIn">
      <div class="nk-main">
        <app-sidebar v-if="appReady"></app-sidebar>
        <div class="nk-wrap" :class="{ 'nk-wrap-nosidebar': !isUserLoggedIn }">
          <app-navbar v-if="appReady"></app-navbar>
          <div class="nk-content">
            <div
              class="container-fluid mb-4"
              v-if="!$helper.empty(flashMessage) && false"
            >
              <div class="nk-content-inner">
                <div class="nk-content-body">
                  <div class="nk-block">
                    <div :class="`alert alert-fill alert-${flashMessage.type}`">
                      <strong>{{ flashMessage.message }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <img src="/img/header.png" alt=""> -->
            <router-view />
          </div>
          <app-footer v-if="isUserLoggedIn"></app-footer>
        </div>
      </div>

      <loader
        v-if="$store.state.isLoading && $store.state.requestFlag === null"
      ></loader>

      <file-upload
        :input-id="'global-upload'"
        :extentions="['png', 'jpg', 'jpeg', 'gif', 'ico', 'webp']"
        @after-uploading="$helper.reloadPage()"
        :post-data="$store.state.entityImagePost"
      ></file-upload>
      <bootstrap-alert
        :modalTitle="$store.state.deleteAlert.title"
        :modalContent="$store.state.deleteAlert.content"
        :actionButton="'Yes, Delete'"
        @accept-alert="deletePaginationRecord"
        @close="$store.state.deleteAlert.modalOpen = false"
        :actionButtonClasses="'btn btn-danger'"
        v-if="$store.state.deleteAlert.modalOpen"
      ></bootstrap-alert>
      <spinner
        v-if="$store.state.requestFlag === 'PAGINATION_SEARCH'"
      ></spinner>
      <div id="bg-loading" v-if="optimizing">
        <div class="loader"></div>
        <div class="loader-text text-center">
          <h4 class="text-white">
            Please waiting while optimizing today's stock...
          </h4>
        </div>
      </div>
    </div>
    <router-view v-else />
  </div>
</template>
<script>
import NavBar from "./Shared/NavBar.vue";
import SideBar from "./Shared/SideBar.vue";
import Footer from "./Shared/Footer.vue";
import Loader from "@/components/Loader.vue";
import Spinner from "@/components/Spinner.vue";
import BootstrapAlert from "@/components/BootstrapAlert.vue";
import FileUpload from "@/components/FileUpload.vue";
/** Pusher */
export default {
  name: "app",
  components: {
    "app-navbar": NavBar,
    "app-sidebar": SideBar,
    "app-footer": Footer,
    Loader,
    Spinner,
    BootstrapAlert,
    FileUpload,
  },
  data: () => ({
    optimizing: false,
  }),
  computed: {
    flashMessage() {
      return this.$store.state.flashMessage;
    },
    appSettings() {
      return this.$store.state.settings;
    },
    appReady() {
      return (
        !this.$helper.empty(this.loggedUser.branch) ||
        (this.isUserLoggedIn &&
          (!this.$helper.empty(this.userPermissions) ||
            (!this.$helper.empty(this.userRole) &&
              this.userRole == "ADMINISTRATOR")))
      );
    },
  },
  beforeCreate() {},
  created() {
    this.$http
      .post(
        "frontend/preloaders",
        this.$helper.generateFormData({
          keys: Object.keys(this.appSettings),
        })
      )
      .then((response) => {
        this.$store.commit("SET_APP_SETTINGS", response.data);
      });
  },
  methods: {
    handleStockOptimization() {
      this.$http.get("stock/check-end-day").then((response) => {
        if (response.data.need_end_of_day) {
          this.$store.state.requestFlag = "OPTIMIZING_STOCK";
          this.optimizing = true;
          this.$http.get("stock/perform-eod").then((response) => {
            if (response.data.status) {
              this.optimizing = false;
            }
          });
        }
      });
    },
    deletePaginationRecord() {
      let recordDeleter = this.$store.state.recordDeleter;
      this.$store.state.requestFlag = "deletingRecord";
      this.$http.get(recordDeleter.url).then((response) => {
        if (!response.data.error) {
          this.$store.state.deleteAlert.modalOpen = false;
          let callback = this.$store.state.recordDeleter.callback;
          if (!this.$helper.empty(callback)) {
            callback();
            delete this.$store.state.recordDeleter.callback;
          } else window.location.reload();
        }
      });
    },
  },
  mounted() {
    if (this.isUserLoggedIn) {
      this.$http.get("auth/me").then((response) => {
        this.$store.commit("SET_CURRENT_USER", { ...response.data.user });
      });
      this.$http.get("branches?order=name&mode=ASC").then((response) => {
        this.$store.commit("SET_BRANCHES", response.data.branches);
      });
      /* this.handleStockOptimization(); */

      this.$nextTick(() => {
        (function (_this) {
          var time;
          document.onload = resetTimer;
          document.onmousemove = resetTimer;
          document.onmousedown = resetTimer;
          document.ontouchstart = resetTimer;
          document.onclick = resetTimer;
          document.onkeydown = resetTimer;
          document.addEventListener("scroll", resetTimer, true);

          function logout() {
            clearTimeout(time);
            _this.$http
              .post("auth/logout", _this.$helper.generateFormData({}))
              .then((response) => {
                if (response.data.status) {
                  _this.$store.dispatch("logout").then(() => {
                    window.location.replace("/");
                  });
                }
              });
          }

          function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, 1800000); // 30 minutes
          }
        })(this);
      });
    }
  },
  watch: {
    $route(to) {
      if (to.name == "login") {
        document.body.classList.add("pg-auth");
        document.body.classList.remove("has-sidebar");
      } else {
        document.body.classList.remove("pg-auth");
        document.body.classList.add("has-sidebar");
      }
      /*if (to.name == "StoreStatus") {
        this.handleStockOptimization();
      } */
    },
    flashMessage() {
      if (this.flashMessage.message) {
        setTimeout(() => {
          this.$store.commit("SET_FLASH_MESSAGE", {});
        }, 5000);
      }
    },
  },
};
</script>
<style lang="css">
.nk-header-tools {
  padding-top: 10px;
  padding-bottom: 10px;
}
.nk-block-head,
.nk-block-head-sm {
  top: 60px;
}
.nk-header-fixed + .nk-content {
  margin-top: 35px;
}
</style>
