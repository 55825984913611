export default {
  install(Vue, args = {}) {
    Vue.prototype.$alert = (options) => {
      var div = document.createElement("div");
      div.setAttribute("id", "alert-container");
      const alert = ` <div
        class="modal fade show"
        id="modal-default"
        tabindex="-1"
        role="dialog"
        style="display: block; z-index: 1081;"
      >
        <div
          class="modal-dialog modal- modal-dialog-centered modal-"
          role="document"
        >
          <div class="modal-content rounded-0" style="border-color: transparent">
            <div class="modal-header px-2 py-1">
              <h6 class="modal-title">
                ${options.title}
              </h6>
              <a
                href="javascript:void(0)"
                class="close"
              >
                <em class="icon ni ni-cross"></em
              ></a>
            </div>
  
            <div class="modal-body text-left">
                ${options.content}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="mr-auto ${options.classes}"
                id="action-btn"
              >
                <span id="action-text">${options.actionText}</span>
                <p id="alert-loading"><span></span><span></span><span></span></p>
              </button>
              <button
                type="button"
                class="btn btn-dark ms-auto"
                id="close-btn"
              >
              <span>${options.closeText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>`;
      div.innerHTML = alert;
      div.querySelector(".close").addEventListener("click", () => {
        document.querySelector("#alert-container").remove();
        args.store.state.hasActiveModal = false;
      });
      div.querySelector("#close-btn").addEventListener("click", () => {
        document.querySelector("#alert-container").remove();
        args.store.state.hasActiveModal = false;
      });
      div.querySelector("#action-btn").addEventListener("click", () => {
        args.store.commit("REGISTER_CONFIRM_MODAL");
        args.store.state.requestFlag = "handling_alert_action";
        div.querySelector("#action-text").remove();
        div.querySelector("#alert-loading").classList.add('d-block');
        options.action();
      });
      document.body.prepend(div);
    };
  },
};
