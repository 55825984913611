import { uploadsUrl } from "@/_helpers/utils.js";
export const globalMixin = {
  data: () => ({
    modalOpen: false,
    filterKey: null,
    publicPath: process.env.BASE_URL,
    permissionsPath: null,
  }),
  computed: {
    isUserLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userPermissions() {
      if (this.$store.getters.user) {
        let role = this.$store.getters.user.role;
        if (role) return role.permissions || {};
      }
      return {};
    },
    appSettings() {
      let settings = this.$store.state.settings || {};
      return settings.result || {};
    },
    loggedUser() {
      return this.$store.getters.user || {};
    },
    userRole() {
      if (this.loggedUser.role) return this.loggedUser.role.name;
    },
    currentBranch() {
      return this.$store.getters.branch;
    },
    paymentModes() {
      return this.$store.getters.modes;
    },
    accounts() {
      return this.$store.getters.accounts;
    },
  },
  methods: {
    getSiteLogo() {
      const siteLogo = this.appSettings?.site_logo;
      if (siteLogo) {
        return `${uploadsUrl + siteLogo}`;
      }
      return `${this.publicPath}img/logo.jpg`;
    },
    toggleModal() {
      this.$helper.toggleModalOpen();
      this.modalOpen = !this.modalOpen;
    },
    handleDeleteAlertModal(args) {
      this.$store.state.recordDeleter.entityName = args.tableName;
      this.$store.state.recordDeleter.url = args.url;
      if (!this.$helper.empty(args.callback)) {
        this.$store.state.recordDeleter.callback = args.callback;
      }
      this.$store.state.deleteAlert = {
        title: "Deleting a record",
        content:
          "Are you sure you want to delete this " +
          args.entityIdentifer +
          ": <b>" +
          args.entity +
          "?</b> <br/> If you continue, there is no way to undo changes you have made.",
        actionButton: "Yes, delete",
        modalOpen: true,
      };
    },
    canView(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return this.userRole == "ADMINISTRATOR" || access[0] == 1;
    },
    canCreate(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return (
        !this.$helper.empty(this.loggedUser.branch) ||
        this.userRole == "ADMINISTRATOR" ||
        access[1] == 1
      );
    },
    canEdit(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return (
        !this.$helper.empty(this.loggedUser.branch) ||
        this.userRole == "ADMINISTRATOR" ||
        access[2] == 1
      );
    },
    canDelete(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return (
        !this.$helper.empty(this.loggedUser.branch) ||
        this.userRole == "ADMINISTRATOR" ||
        access[3] == 1
      );
    },
    formatMoney(num) {
      return `${this.appSettings.currency} ${this.$helper.formatNumber(num)}`;
    },
    getPaymentsMode() {
      if (!this.paymentModes.length) {
        this.$store.commit("SET_REQUEST_FLAG", "GET_PAYMENTS_MODE");
        this.$http.get("payments/modes").then((response) => {
          this.$store.commit("SET_PAYMENT_MODES", response.data.rows);
        });
      }
    },
    getPaymentsAccounts() {
      if (!this.accounts.length) {
        this.$store.commit("SET_REQUEST_FLAG", "GET_PAYMENTS_ACCOUNTS");
        this.$http.get("accounts").then((response) => {
          this.$store.commit("SET_PAYMENT_ACCOUNTS", response.data.rows);
        });
      }
    },
    monthDatesQuery() {
      const dates = this.$helper.getMonthBoundaries(String(TODAY));
      return { start_date: dates[0], end_date: dates[1] };
    },
  },
};
